import {FormFieldBase, FormFieldBaseOptions} from './form-field-base';

export type FormFieldCheckBoxOptions<T = any> = FormFieldBaseOptions<T>;

export class FormFieldCheckbox<T = any> extends FormFieldBase<T> {
    controlType = 'checkbox';

    constructor(options: FormFieldCheckBoxOptions<T> = {}) {
        super(options);
    }
}
