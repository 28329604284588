import {FormPropertyBase, FormPropertyBaseOptions} from './form-property-base';

export interface FormBlockOptions<T = any> extends FormPropertyBaseOptions<T> {
    fields?: FormPropertyBase[];
    multi?: boolean;
    style?: FormBlockStyle;
}

export type FormBlockStyle = null | 'SINGLE_ROW';

export class FormBlock<T = any> extends FormPropertyBase<T> {
    fields: FormPropertyBase[] = [];
    multi = false;
    style: FormBlockStyle;

    constructor(options: FormBlockOptions<T> = {}) {
        super(options);
        this.fields = options.fields || [];
        this.multi = options.multi || false;
        this.style = options.style || null;
    }
}
